import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  update(e: Event): void {
    const target = e.target as HTMLInputElement;
    const previewParams = new URLSearchParams(window.location.search);

    if (!target) {
      return;
    }

    previewParams.set(target.name, target.value);
    window.parent.postMessage({ type: 'previewUpdated', previewParams: previewParams.toString() }, '*');
  }
}
