import { Controller } from '@hotwired/stimulus';
import bookBoardUsersOutletController from 'controllers/book_board_users_controller';

export default class extends Controller {
  static values = {
    usersPath: String,
  };

  static targets = ['zoom', 'zoomCheckbox', 'teams', 'googleMeet', 'web', 'notweb'];

  declare usersPathValue: string;
  declare zoomTargets: HTMLElement[];
  declare teamsTargets: HTMLElement[];
  declare googleMeetTargets: HTMLElement[];
  declare webTargets: HTMLElement[];
  declare notwebTargets: HTMLElement[];
  declare hasBookBoardUsersOutlet: boolean;

  static outlets = ['book-board-users'];
  declare bookBoardUsersOutlet: bookBoardUsersOutletController;

  connect(): void {
    this.swichDisplay();
  }

  swichDisplay(): void {
    this.zoomTargets.forEach((element) => {
      element.dataset.originalDisplay = element.style.display;
      element.style.display = element.dataset.show === 'true' ? element.dataset.originalDisplay : 'none';
    });
    this.teamsTargets.forEach((element) => {
      element.dataset.originalDisplay = element.style.display;
      element.style.display = element.dataset.show === 'true' ? element.dataset.originalDisplay : 'none';
    });
    this.googleMeetTargets.forEach((element) => {
      element.dataset.originalDisplay = element.style.display;
      element.style.display = element.dataset.show === 'true' ? element.dataset.originalDisplay : 'none';
    });
    this.webTargets.forEach((element) => {
      element.dataset.originalDisplay = element.style.display;
      element.style.display = element.dataset.show === 'true' ? element.dataset.originalDisplay : 'none';
    });
    this.notwebTargets.forEach((element) => {
      element.dataset.originalDisplay = element.style.display;
      element.style.display = element.dataset.show === 'true' ? element.dataset.originalDisplay : 'none';
    });
  }

  changeStyle(event: MouseEvent): void {
    const target = event.currentTarget as HTMLInputElement;
    if (!target.checked) {
      return;
    }

    if (this.hasBookBoardUsersOutlet) {
      // outlet では直接プロパティを変更できないので引数で渡す
      this.bookBoardUsersOutlet.resetMeetingStyle(target.value);
    }

    const elements = document.getElementsByClassName('meeting_styles_button__select_item');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active');
      switch (target.value) {
        case 'google_meet':
          elements[0].classList.add('active');
          break;
        case 'zoom':
          elements[1].classList.add('active');
          break;
        case 'offline':
          elements[2].classList.add('active');
          break;
        case 'teams':
          elements[3].classList.add('active');
          break;
        case 'tel':
          elements[5].classList.add('active');
          break;
        case 'notice':
          elements[6].classList.add('active');
          break;
        default:
          elements[4].classList.add('active');
      }
    }

    switch (target.value) {
      case 'zoom':
        this.showZoomContent();
        this.showWebContent();
        this.hideTeamsContent();
        this.hideGoogleMeetContent();
        this.hideOfflineContent();
        break;
      case 'teams':
        this.showTeamsContent();
        this.showWebContent();
        this.hideZoomContent();
        this.hideGoogleMeetContent();
        this.hideOfflineContent();
        break;
      case 'google_meet':
        this.showGoogleMeetContent();
        this.showWebContent();
        this.hideZoomContent();
        this.hideTeamsContent();
        this.hideOfflineContent();
        break;
      default:
        this.showOfflineContent();
        this.hideZoomContent();
        this.hideTeamsContent();
        this.hideGoogleMeetContent();
        this.hideWebContent();
    }
  }

  switchMemberTab(): void {
    const teamTabButton = document.querySelector<HTMLButtonElement>('[data-tab="team"]');
    if (teamTabButton) {
      teamTabButton.click();
    }
  }

  switchContentMeetingStyle(styleName): void {
    switch (styleName) {
      case 'zoom':
        this.showZoomContent();
        this.showWebContent();
        this.hideTeamsContent();
        this.hideGoogleMeetContent();
        this.hideOfflineContent();
        break;
      case 'teams':
        this.showTeamsContent();
        this.showWebContent();
        this.hideZoomContent();
        this.hideGoogleMeetContent();
        this.hideOfflineContent();
        break;
      case 'google_meet':
        this.showGoogleMeetContent();
        this.showWebContent();
        this.hideZoomContent();
        this.hideTeamsContent();
        this.hideOfflineContent();
        break;
      default:
        this.showOfflineContent();
        this.hideZoomContent();
        this.hideTeamsContent();
        this.hideGoogleMeetContent();
        this.hideWebContent();
    }
  }

  switchContentMultiMeetingStyle(options: HTMLOptionsCollection): void {
    const selectedOptions = Array.from(options);

    this.hideGoogleMeetContent();
    this.hideTeamsContent();
    this.hideZoomContent();
    this.hideWebContent();
    this.hideOfflineContent();
    let is_web = false;

    selectedOptions.forEach((option) => {
      const val = option.value ? option.value : option;
      switch (val) {
        case 'zoom':
          this.showZoomContent();
          this.showWebContent();
          is_web = true;
          return;
        case 'teams':
          this.showTeamsContent();
          this.showWebContent();
          is_web = true;
          return;
        case 'google_meet':
          this.showGoogleMeetContent();
          this.showWebContent();
          is_web = true;
          return;
      }
    });

    if (!is_web) this.showOfflineContent();
  }

  showZoomContent(): void {
    this.zoomTargets.forEach((element) => {
      element.style.display = element.dataset.originalDisplay;
    });
  }

  hideZoomContent(): void {
    this.zoomTargets.forEach((element) => {
      element.style.display = 'none';
    });
  }

  showTeamsContent(): void {
    this.teamsTargets.forEach((element) => {
      element.style.display = element.dataset.originalDisplay;
    });
  }

  hideTeamsContent(): void {
    this.teamsTargets.forEach((element) => {
      element.style.display = 'none';
    });
  }

  showGoogleMeetContent(): void {
    this.googleMeetTargets.forEach((element) => {
      element.style.display = element.dataset.originalDisplay;
    });
  }

  hideGoogleMeetContent(): void {
    this.googleMeetTargets.forEach((element) => {
      element.style.display = 'none';
    });
  }

  showWebContent(): void {
    this.webTargets.forEach((element) => {
      element.style.display = element.dataset.originalDisplay;
    });
  }

  hideWebContent(): void {
    this.webTargets.forEach((element) => {
      element.style.display = 'none';
    });
  }

  showOfflineContent(): void {
    this.notwebTargets.forEach((element) => {
      element.style.display = element.dataset.originalDisplay;
    });
  }

  hideOfflineContent(): void {
    this.notwebTargets.forEach((element) => {
      element.style.display = 'none';
    });
  }
}
